import React, { useState } from "react";
import { Table, Button, Space, Modal, Input, message } from "antd";
import { addMapping, updateMapping, deleteMapping } from "../../../api/mapping";

const CRM = ({ crmData, onRefresh }) => {
  const [editingName, setEditingName] = useState(""); // For editing name
  const [editingKey, setEditingKey] = useState(null); // Track which row is being edited
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [isAddModalVisible, setIsAddModalVisible] = useState(false); // Modal visibility state for adding
  const [data, setData] = useState(
    crmData.map((item, index) => ({
      key: index,
      id: item?.id,
      name: item?.name, // Trim any extra spaces from names
    }))
  );

  const handleDelete = async (id) => {
    try {
      await deleteMapping(id);
      setData(data.filter((item) => item.id !== id));
      message.success("Deleted successfully.");
      onRefresh();
    } catch (error) {
      message.error("Failed to delete.");
    }
  };

  const handleEdit = (key) => {
    const recordToEdit = data.find((item) => item.key === key);
    if (recordToEdit) {
      setEditingKey(key);
      setEditingName(recordToEdit.name);
      setIsModalVisible(true);
    }
  };

  const handleSave = async () => {
    if (!editingName.trim()) {
      message.warning("Name cannot be empty.");
      return;
    }

    const recordToEdit = data.find((item) => item.key === editingKey);
    if (recordToEdit) {
      try {
        await updateMapping("0", editingName, recordToEdit.id);
        const newData = data.map((item) =>
          item.key === editingKey ? { ...item, name: editingName } : item
        );
        setData(newData);
        message.success("Updated successfully.");
        setIsModalVisible(false);
        setEditingKey(null);
        // onRefresh();
      } catch (error) {
        message.error("Failed to update.");
      }
    }
  };

  const handleAdd = async () => {
    if (!editingName.trim()) {
      message.warning("Name cannot be empty.");
      return;
    }

    try {
      const addedItem = await addMapping("0", editingName);
      const newData = [
        ...data,
        { key: data.length, id: addedItem.id, name: editingName },
      ];
      setData(newData);
      message.success("Added successfully.");
      setIsAddModalVisible(false);
      setEditingName(""); // Clear the input field
      onRefresh();
    } catch (error) {
      message.error("Failed to add.");
    }
  };

  const columns = [
    {
      title: "CRM Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit(record.key)} type="primary">
            Edit
          </Button>
          <Button onClick={() => handleDelete(record.id)} type="danger">
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() => setIsAddModalVisible(true)}
        style={{ marginBottom: 16 }}
      >
        Add CRM Name
      </Button>

      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey="id"
        bordered
      />

      <Modal
        title="Edit CRM Name"
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={() => setIsModalVisible(false)}
      >
        <Input
          value={editingName}
          onChange={(e) => setEditingName(e.target.value)}
        />
      </Modal>

      <Modal
        title="Add New CRM Name"
        visible={isAddModalVisible}
        onOk={handleAdd}
        onCancel={() => setIsAddModalVisible(false)}
      >
        <Input
          value={editingName}
          onChange={(e) => setEditingName(e.target.value)}
          placeholder="Enter CRM Name"
        />
      </Modal>
    </div>
  );
};

export default CRM;
