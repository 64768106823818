import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IVR from "./components/IVR/IVR";
import Incoming from "./components/Incoming/Incoming";
import Airtel from "./components/Airtel/Airtel/Airtel";
import EmployeePerDay from "./components/Airtel/EmployeePerDay";
import Header from "./components/General/Header";
import Employee from "./components/Airtel/Employee";
import Collections from "./components/Airtel/Collections";
import UnauthorizedPage from "./components/Handler/UnauthorizedPage";
import ClassWiseEmployeeDetails from "./components/Airtel/ClassWiseEmployeeDetails";
import Canada from "./components/Canada/Canada";
import Dispo from "./components/Dispo/Dispo";
import CallHippo from "./components/CallHippo/CallHippo";
import LoginPage from "./components/Authentication/Login";

import Report from "./components/Airtel/Agent_Customer_Talk_Time_Report";
import DialerReportAirtel from "./components/Airtel/Dialer/DialerReport";
import AirtelACTalkTimeReport from "./components/Airtel/Airtel/Airtel_Agent_Customer_Talk_Time_Report";
import { Toaster } from "react-hot-toast";
import DNDFalseInput from "./components/Airtel/FalseInputDetails/DNDFalseInput";
import Whatsapp from "./components/Whatsapp/Whatsapp";
import Team from "./components/Team/Team";
import Dialer from "./components/Dispo/Report/Dialer";
import Insights from "./components/Insights/Insights";
import Export_data from "./components/Export/Export_data";
import Form from "./components/ComplainForm/Form";
import DailyReport from "./components/Airtel/Dialer/DailyReport";
import DialerAgentReport from "./components/Dialer/DialerAgentReport";

//Contexts
import { QrtProvider } from "./Context/QrtContext";
import Main from "./components/Airtel/Main";
import Recording from "./components/Airtel/Airtel/Recording";
import MenuLeads from "./components/MenuLeads/MenuLeads";
import TataTeleIncome from "./components/Dispo/TataTeleIncome";
import MenuUtils from "./components/MenuLeads/MenuUtils";
import MenuMain from "./components/MenuLeads/MenuMain";
import Pickup from "./components/Pickup/Pickup";
import Tab_Dashboard from "./components/Report/Tab_Dashboard";
import Egniol_Updates from "./components/Egniol_Updates/Egniol_Updates";
import AgentTalkTimeReport from "./components/Dialer/AgentTalkTimeReport";

function App() {
  return (
    <QrtProvider>
      <Router>
        <Header />
        <Toaster />
        <Routes initialRouteName="/login">
          <Route path="/" element={<Dispo />} />
          {/* <Route path="/pickup" element={<Pickup />} /> */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/team" element={<Team />} />
          <Route path="/ivr" element={<IVR />} />
          <Route path="/incoming" element={<Incoming />} />
          <Route path="/incoming/:today" element={<Incoming />} />
          <Route path="/incoming/:start/:end" element={<Incoming />} />
          <Route path="/openPhone" element={<Canada />} />
          <Route path="/openPhone/:start/:end" element={<Canada />} />
          <Route path="/callhippo" element={<CallHippo />} />
          <Route path="/dispo" element={<Dispo />} />
          <Route path="/incomeTax" element={<TataTeleIncome />} />
          <Route path="/dialerDispo" element={<Dispo />} />
          <Route path="/menuLeads" element={<MenuMain />} />
          <Route path="/airtel" element={<Main />} />
          <Route path="/airtel/recording" element={<Recording />} />
          <Route path="/employee" element={<Employee />} />
          <Route path="/employee-collections" element={<Collections />} />
          <Route path="/employeePerDay" element={<EmployeePerDay />} />
          <Route
            path="/class-wise-employee-data"
            element={<ClassWiseEmployeeDetails />}
          />
          <Route path="/agent-customer-report" element={<Report />} />
          <Route
            path="/agent-customer-airtel-report"
            element={<AirtelACTalkTimeReport />}
          />
          <Route path="/false-dnd-input-data" element={<DNDFalseInput />} />
          <Route path="/dialer-report" element={<Dialer />} />
          <Route path="/insights" element={<Insights />} />
          <Route
            path="/dialer-report-airtel"
            element={<DialerReportAirtel />}
          />
          <Route path="/dialer-report-daily" element={<DailyReport />} />
          <Route path="/reports" element={<Tab_Dashboard />} />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route path="/Whatsapp" element={<Whatsapp />} />
          <Route path="/export_data" element={<Export_data />} />
          <Route path="/complain_form" element={<Form />} />
          <Route path="/egniol_updates" element={<Egniol_Updates />} />
          <Route path="/dialer_agent_report" element={<DialerAgentReport />} />
          <Route
            path="/agent_talk_time_report"
            element={<AgentTalkTimeReport />}
          />
        </Routes>
      </Router>
    </QrtProvider>
  );
}

export default App;
