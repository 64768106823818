import React, { useState } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv'; // Import CSVLink

const AgentTalkTimeReport = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dialerData, setDialerData] = useState([]);
    const [airtelData, setAirtelData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    function secondsToHMS(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        // Pad with leading zeros if necessary
        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(secs).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!startDate || !endDate) {
            setError('Both start date and end date are required.');
            return;
        }

        setError(null);
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/api/dialer/agent_taltime_report`, {
                startDate,
                endDate,
            });
            const formattedData = Object.keys(response.data.data).map((agent_name) => ({
                agent_name: agent_name,
                duration: secondsToHMS(response.data.data[agent_name].total_duration),
            }))
            setDialerData(formattedData);
        } catch (err) {
            console.log(err)
            setError('Failed to fetch report. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const headers = [
        { label: 'Agent Name', key: 'agent_name' },
        { label: 'Total Talk Time', key: 'duration' },
    ];

    return (
        <div className="container mx-auto p-6 bg-gray-100 min-h-screen">
            <div className="max-w-4xl mx-auto shadow-lg bg-white p-8 rounded-lg">
                <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">Agent Talk Time Report</h1>

                {/* Date Range Form */}
                <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-8">
                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">Start Date:</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-gray-700 font-semibold mb-2">End Date:</label>
                        <input
                            type="date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                            required
                        />
                    </div>

                    <div className="col-span-1 sm:col-span-2 text-center">
                        <button
                            type="submit"
                            className={`px-6 py-3 bg-indigo-600 text-white rounded-lg font-semibold transition-all duration-300 
                            hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-500 focus:ring-opacity-50 disabled:bg-gray-400`}
                            disabled={loading}
                        >
                            {loading ? 'Loading...' : 'Get Report'}
                        </button>
                    </div>
                </form>

                {/* Error Handling */}
                {error && <div className="text-red-600 text-center mb-4">{error}</div>}

                {/* Display Tables */}
                <div className="overflow-x-auto">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">Dialer Results</h2>
                    <table className="w-full bg-white rounded-lg shadow-md text-left">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="py-3 px-4 font-semibold text-gray-700">Agent Name</th>
                                <th className="py-3 px-4 font-semibold text-gray-700">Total Talk Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dialerData.sort((a, b) => b.duration - a.duration).map((row) => (
                                <tr key={row.agent_name} className="border-b hover:bg-gray-50">
                                    <td className="py-3 px-4">{row.agent_name}</td>
                                    <td className="py-3 px-4">{row.duration}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>



                {/* CSV Download Links */}
                <div className="mt-10 text-center">
                    <h3 className="text-lg font-semibold text-gray-800 mb-4">Download Reports</h3>
                    <div className="flex justify-center space-x-6">
                        {/* Dialer CSV Link */}
                        {dialerData.length > 0 && (
                            <CSVLink
                                headers={headers}
                                data={dialerData}
                                filename={`dialer_report_${startDate}_to_${endDate}.csv`}
                                className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-lg font-semibold transition duration-300"
                            >
                                Download Dialer Report
                            </CSVLink>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgentTalkTimeReport;
// {/* <div className="overflow-x-auto mt-6">
//                     <h2 className="text-xl font-semibold text-gray-800 mb-4">Airtel Results</h2>
//                     <table className="w-full bg-white rounded-lg shadow-md text-left">
//                         <thead className="bg-gray-100">
//                             <tr>
//                                 <th className="py-3 px-4 font-semibold text-gray-700">Agent Name</th>
//                                 <th className="py-3 px-4 font-semibold text-gray-700">Total Talk Time</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {airtelData.sort((a, b) => b.duration - a.duration).map((row) => (
//                                 <tr key={row.agent_name} className="border-b hover:bg-gray-50">
//                                     <td className="py-3 px-4">{row.agent_name}</td>
//                                     <td className="py-3 px-4">{row.total_ans_calls}</td>
//                                     <td className="py-3 px-4">{row.total_miss_calls}</td>
//                                     <td className="py-3 px-4">{secondsToHMS(parseInt(row.duration))}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div> */}
// {/* Airtel CSV Link */}
// {airtelData.length > 0 && (
//     <CSVLink
//         headers={headers}
//         data={airtelData}
//         filename={`airtel_report_${startDate}_to_${endDate}.csv`}
//         className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg font-semibold transition duration-300"
//     >
//         Download Airtel Report
//     </CSVLink>
// )}
