import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import moment from "moment";
import { addReport } from "../../api/report";
import toast, { Toaster } from "react-hot-toast";
import { Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";

function Ans_Miss_Report({ selectedOption }) {
  const [teamDataArray, setTeamDataArray] = useState([]);
  const [summary, setSummary] = useState([]);
  const [csvLink, setCsvLink] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  function getKeyForElement(element) {
    const lowerElement = element.toLowerCase();
    for (const key in mappedKeys) {
      const lowerKeys = mappedKeys[key].map((item) => item.toLowerCase());
      if (lowerKeys.includes(lowerElement)) {
        return key;
      }
    }
    return null; // If no key found for the element
  }

  const mappedKeys = {
    ["Disposition Name"]: [
      "Agent Disposition",
      "Disposition Name",
      "status_name",
    ],
    ["Total Call Duration (HH:MM:SS)"]: [
      "Customer Talk Duration",
      "Total Call Duration (HH:MM:SS)",
      "length_in_sec",
    ],
    ["Call Status"]: ["Call Status"],
    ["Campaign Name"]: ["Campaign Name", "Campaign Name", "campaign_name"],
    ["Connected to Agent"]: ["Connected to Agent", "Agent Name", "full_name"],
  };

  const specificDispositions = [
    "call rejected",
    "channel issue",
    "issue with receiver network",
    "noans",
    "network issue",
    "invalid dialing",
    "lead being called",
    "not reachable",
    "receiver is busy",
    "number changed",
    "system failure",
    "unallocated number",
    "not pickup",
    "Ring Timeout",
  ];

  const header = [
    "busy",
    "call back",
    "dnd",
    "interested",
    "language issue",
    "300 sec",
    "need update",
    "not interested",
    "not pickup",
    "scrap",
    "undisposed",
  ];

  // Function to convert duration to seconds
  function durationToSeconds(duration) {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }
  const isSpecificDisposition = (disposition) => {
    return specificDispositions.includes(disposition);
  };
  function parseCSVData(csvText) {
    return new Promise((resolve, reject) => {
      Papa.parse(csvText, {
        header: true,
        complete: (results) => {
          let framedData = [];
          let keys = [];
          Object.keys(results.data[0]).map((key) => {
            keys.push({ mappedKey: getKeyForElement(key), originalKey: key });
          });
          results.data.map((data) => {
            let obj = {};
            keys.forEach(({ originalKey, mappedKey }) => {
              obj[mappedKey] = data[originalKey];
            });
            framedData.push(obj);
          });

          resolve(framedData);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  async function readAndParseFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const csvText = event.target.result;
          const parsedData = await parseCSVData(csvText);
          resolve(parsedData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsText(file);
    });
  }

  const formatData = (obj) => {
    let formatedDataArray = [];
    Object.keys(obj).map((key) => {
      formatedDataArray.push({
        team: key,
        ...obj[key],
      });
    });

    return formatedDataArray;
  };
  const handleSave = async () => {
    try {
      const jsonData = JSON.stringify(teamDataArray);

      const data = await addReport(jsonData, selectedOption);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error("Error while saving:", error);
      toast.error("Error occurred while saving the report");
    }
  };
  const parseFileData = async (files) => {
    try {
      const dataArray = await Promise.all(
        files.map(async (file) => {
          return await readAndParseFile(file);
        })
      );
      const mergedArray = [].concat(...dataArray); // or const mergedArray = dataArray.flat();
      return mergedArray;
    } catch (error) {
      throw error;
    }
  };
  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (!files) return;
    const newSelectedFiles = [...selectedFiles, files[0]];
    const overallData = await parseFileData(newSelectedFiles);
    setDataSource(overallData);
    setSelectedFiles(newSelectedFiles);
  };

  const handleGenerateReport = () => {
    // Function to generate the report when the button is clicked
    processData();
  };

  const convertToCSV = (arr) => {
    if (!arr || arr.length === 0) {
      console.error("Input array is empty or undefined");
      return;
    }

    const header = Object.keys(arr[0]).join(",");
    const rows = arr.map((obj) => Object.values(obj).join(","));
    return header + "\n" + rows.join("\n");
  };

  const downloadCSV = (arr) => {
    if (!arr || arr.length === 0) {
      console.error("Input array is empty or undefined");
      return;
    }

    const csvContent = convertToCSV(arr);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    setCsvLink(url);
  };
  const captureAndDownload = () => {
    const element = document.getElementById("Answered_Miss_Report"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `Answered_Missed_Report_${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const processData = () => {
    let framedData = {};
    dataSource.forEach((row) => {
      if (
        !row["Disposition Name"] ||
        !row["Campaign Name"] ||
        !row["Call Status"] ||
        row["Campaign Name"].toLowerCase().trim() === "team france"
      ) {
      } else {
        let disposition = row["Disposition Name"].toLowerCase().trim();
        const team = row["Campaign Name"].toLowerCase().trim(); // Convert to uppercase
        const callStatus = row["Call Status"].toLowerCase().trim();
        const duration = row["Total Call Duration (HH:MM:SS)"]
          ? durationToSeconds(row["Total Call Duration (HH:MM:SS)"])
          : 0;

        if (disposition !== "interested" && duration >= 300) {
          disposition = "300 sec";
        }
        if (disposition === "wrong number") {
          disposition = "scrap";
        }

        if (!framedData[team]) {
          framedData[team] = {
            ["busy"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
            ["call back"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
            ["interested"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
            ["language issue"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
            ["need update"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
            ["not interested"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
            ["not pickup"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
            ["300 sec"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
            ["dnd"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
            ["scrap"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
            ["undisposed"]: {
              answered: 0,
              missed: 0,
              total: 0,
            },
          };
        }
        if (!framedData[team][disposition]) {
          framedData[team][disposition] = {
            answered: 0,
            missed: 0,
            total: 0,
          };
        }

        if (isSpecificDisposition(disposition)) {
          framedData[team]["not pickup"][callStatus]++;
          framedData[team]["not pickup"]["total"]++;
        } else {
          framedData[team][disposition][callStatus]++;
          framedData[team][disposition]["total"]++;
        }
      }
    });
    let dispositionTotals = {};
    header.forEach((disposition) => {
      let total = 0;
      Object.values(framedData).forEach((teamData) => {
        if (teamData[disposition]) {
          total += teamData[disposition]["total"];
        }
      });
      dispositionTotals[disposition] = total;
    });

    const formatedDataArray = formatData(framedData);

    setTeamDataArray(formatedDataArray);
    setSummary(dispositionTotals);
    downloadCSV(formatedDataArray);
    handleSave();
  };

  const renderTable = () => {
    let grandTotalAnsweredA = 0;
    let grandTotalMissedA = 0;
    let grandTotalTotalA = 0;
    let grandTotalAnsweredB = 0;
    let grandTotalMissedB = 0;
    let grandTotalTotalB = 0;
    let grandTotal = 0;

    let grandInterestedAnsA = 0;
    let grandInterestedMissA = 0;
    let grandInterestedTotalA = 0;
    let grandInterestedAnsB = 0;
    let grandInterestedMissB = 0;
    let grandInterestedTotalB = 0;
    let grandInterested = 0;

    return (
      <table className="m-auto mt-3" id="Answered_Miss_Report">
        <thead>
          <tr
            className={`text-center border-black border bg-[#fffe00] font-bold`}
          >
            <td colSpan={10} className="pb-3">
              Answered & Missed Report {moment().format("DD-MM-YYYY")}
            </td>
          </tr>
          <tr className={`border text-center`}>
            <th
              className={`text-center border-black pb-3 p-1 border bg-[#c9c9c9] font-bold`}
            >
              Sr. No
            </th>
            <th
              className={`text-center border-black border pb-3 p-1 bg-[#c9c9c9] font-bold`}
            >
              Disposition
            </th>
            <th
              className={`text-center border-black p-1 border pb-3  bg-[#B7c3ea] font-bold`}
              colSpan={3}
            >
              TEAM A
            </th>
            <th
              className={`text-center border-black border pb-3 p-1  bg-[#fed966] font-bold`}
              colSpan={3}
            >
              TEAM B
            </th>
            <th
              className={`text-center border-black border pb-3 p-1  bg-[#93d14c] font-bold`}
            >
              GRAND TOTAL
            </th>
          </tr>
          <tr>
            <th
              className={`text-center border-black border pb-3 p-1  bg-[#c9c9c9] font-bold`}
              colSpan={2}
            ></th>
            {/* <th></th> */}
            <th
              className={`text-center border-black border pb-3 p-1  bg-[#B7c3ea] font-bold`}
            >
              Answered
            </th>
            <th
              className={`text-center border-black border pb-3 p-1  bg-[#B7c3ea] font-bold`}
            >
              Missed
            </th>
            <th
              className={`text-center border-black border pb-3 p-1  bg-[#B7c3ea] font-bold`}
            >
              Total
            </th>
            <th
              className={`text-center border-black border pb-3 p-1  bg-[#fed966] font-bold`}
            >
              Answered
            </th>
            <th
              className={`text-center border-black border pb-3 p-1  bg-[#fed966] font-bold`}
            >
              Missed
            </th>
            <th
              className={`text-center border-black border pb-3 p-1  bg-[#fed966] font-bold`}
            >
              Total
            </th>
            <th
              className={`text-center border-black border pb-3 p-1  bg-[#93d14c] font-bold`}
            ></th>
          </tr>
        </thead>
        <tbody>
          {header.map((disposition, index) => {
            const answeredA = teamDataArray[0][disposition]?.answered || 0;
            const missedA = teamDataArray[0][disposition]?.missed || 0;
            const totalA = teamDataArray[0][disposition]?.total || 0;
            const answeredB = teamDataArray[1][disposition]?.answered || 0;
            const missedB = teamDataArray[1][disposition]?.missed || 0;
            const totalB = teamDataArray[1][disposition]?.total || 0;
            const total = summary[disposition] || 0;

            grandTotalAnsweredA += answeredA;
            grandTotalMissedA += missedA;
            grandTotalTotalA += totalA;
            grandTotalAnsweredB += answeredB;
            grandTotalMissedB += missedB;
            grandTotalTotalB += totalB;
            grandTotal += total;

            if (disposition === "interested" || disposition === "300 sec") {
              const IntAnsA = teamDataArray[0][disposition]?.answered || 0;
              const IntMissA = teamDataArray[0][disposition]?.missed || 0;
              const IntTotalA = teamDataArray[0][disposition]?.total || 0;
              const IntAnsB = teamDataArray[1][disposition]?.answered || 0;
              const IntMissB = teamDataArray[1][disposition]?.missed || 0;
              const IntTotalB = teamDataArray[1][disposition]?.total || 0;
              const IntTotal = summary[disposition] || 0;

              grandInterestedAnsA += IntAnsA;
              grandInterestedMissA += IntMissA;
              grandInterestedTotalA += IntTotalA;
              grandInterestedAnsB += IntAnsB;
              grandInterestedMissB += IntMissB;
              grandInterestedTotalB += IntTotalB;
              grandInterested += IntTotal;
            }

            return (
              <>
                <tr key={disposition} className={`border text-center`}>
                  <td
                    className={` text-center border pb-3 p-1  font-bold bg- border-black 
                    ${disposition !== "300 sec" ? "bg-[#c9c9c9]" : ""} `}
                  >
                    {index + 1}
                  </td>
                  <td
                    className={`border  pb-3 p-1 text-center  font-bold bg- border-black  ${
                      disposition !== "300 sec" ? "bg-[#c9c9c9]" : ""
                    }`}
                  >
                    {disposition.toUpperCase()}
                  </td>
                  <td
                    className={`border pb-3 text-center p-1 font-bold bg- border-black  ${
                      disposition !== "300 sec" ? "bg-[#B7c3ea]" : ""
                    }`}
                  >
                    {answeredA}
                  </td>
                  <td
                    className={`border pb-3 text-center p-1 font-bold bg- border-black  ${
                      disposition !== "300 sec" ? "bg-[#B7c3ea]" : ""
                    }`}
                  >
                    {missedA}
                  </td>
                  <td
                    className={`border pb-3 text-center p-1 font-bold bg- border-black  ${
                      disposition !== "300 sec" ? "bg-[#B7c3ea]" : ""
                    }`}
                  >
                    {totalA}
                  </td>
                  <td
                    className={`border pb-3 text-center p-1 font-bold bg- border-black  ${
                      disposition !== "300 sec" ? "bg-[#fed966]" : ""
                    }`}
                  >
                    {answeredB}
                  </td>
                  <td
                    className={`border pb-3 text-center p-1 font-bold bg- border-black  ${
                      disposition !== "300 sec" ? "bg-[#fed966]" : ""
                    }`}
                  >
                    {missedB}
                  </td>
                  <td
                    className={`border pb-3 text-center p-1 font-bold bg- border-black  ${
                      disposition !== "300 sec" ? "bg-[#fed966]" : ""
                    }`}
                  >
                    {totalB}
                  </td>
                  <td
                    className={`border pb-3 text-center p-1 font-bold bg- border-black ${
                      disposition !== "300 sec" ? "bg-[#93d14c]" : ""
                    } `}
                  >
                    {summary[disposition]}
                  </td>
                </tr>
              </>
            );
          })}
          {/* Add Grand Total row */}
          <tr className={`border text-center`}>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#fffe00]`}
              colSpan={2}
            >
              Grand Total
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#fffe00]`}
            >
              {grandTotalAnsweredA}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#fffe00]`}
            >
              {grandTotalMissedA}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#fffe00]`}
            >
              {grandTotalTotalA}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#fffe00]`}
            >
              {grandTotalAnsweredB}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#fffe00]`}
            >
              {grandTotalMissedB}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#fffe00]`}
            >
              {grandTotalTotalB}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#fffe00]`}
            >
              {grandTotal}
            </td>
          </tr>

          <tr className={`border text-center`}>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#B7c3ea]`}
              colSpan={2}
            >
              Total Interested
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#B7c3ea]`}
            >
              {grandInterestedAnsA}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#B7c3ea]`}
            >
              {grandInterestedMissA}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#B7c3ea]`}
            >
              {grandInterestedTotalA}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#B7c3ea]`}
            >
              {grandInterestedAnsB}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#B7c3ea]`}
            >
              {grandInterestedMissB}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#B7c3ea]`}
            >
              {grandInterestedTotalB}
            </td>
            <td
              className={`border pb-3 text-center p-1 font-bold bg- border-black  bg-[#B7c3ea]`}
            >
              {grandInterested}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  // Render your table as before based on the filtered teamData

  return (
    <div className="m-5">
      <h1 className="font-semibold">Excel to CSV Converter : </h1>
      <form className="flex flex-row w-fit gap-x-3">
        <input type="file" multiple onChange={handleFileInputChange} />
        <button
          type="button"
          onClick={handleGenerateReport}
          className="border-black cursor-pointer rounded-sm font-semibold border-2 p-1 text-center"
        >
          Generate Report
        </button>
        {csvLink && (
          <>
            <a
              href={csvLink}
              download="report.csv"
              className=" border-black cursor-pointer rounded-sm  font-semibold border-2  p-1 text-center"
            >
              Download CSV
            </a>
            <div
              onClick={captureAndDownload}
              className="border-black cursor-pointer rounded-sm  font-semibold border-2  p-1 text-center"
            >
              <DownloadOutlined style={{ fontSize: "20px", color: "#08c" }} />
              Download PNG
            </div>
          </>
        )}
      </form>
      <div>
        {selectedFiles.length > 0 && (
          <div className="">
            <h2 className="text-lg font-semibold mb-2">Selected Files:</h2>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index} className="flex items-center mb-1">
                  <span className="mr-2">{file.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {teamDataArray.length > 0 && renderTable()}
    </div>
  );
}

export default Ans_Miss_Report;
