import React, { useState, useEffect } from "react";
import { Spin } from "antd";
import CRM from "./Tabs/CRM";
import Airtel from "./Tabs/Airtel";
import Tata from "./Tabs/Tata";
import { getAllMapping } from "../../api/mapping";

const Team = () => {
  const [data, setData] = useState({
    crm: [],
    airtel: [],
    tata: [],
  });
  const [loading, setLoading] = useState(true);

  const fetchMappingData = async () => {
    try {
      setLoading(true); // Show loading spinner
      const results = await getAllMapping();
      console.log("Mapping data fetched:", results.data);

      if (results.data && results.data.length > 0) {
        const crm = results.data.map((item) => ({
          id: item.id,
          name: item.CRM_Name,
        }));
        const airtel = results.data.map((item) => ({
          id: item.id,
          name: item.Egniol_Name,
        }));
        const tata = results.data.map((item) => ({
          id: item.id,
          name: item.Tata_name,
        }));
        setData({ crm, airtel, tata });
      }
    } catch (error) {
      console.error("Error fetching mapping data:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  useEffect(() => {
    fetchMappingData();
  }, []);

  // Function to refresh data from child components
  const refreshData = () => {
    fetchMappingData();
  };

  return (
    <div className="container mx-auto my-8 ml-10">
      <div className="grid grid-cols-3 gap-8">
        <div className="col-span-1">
          <h2>Airtel</h2>
          {loading ? (
            <Spin size="large" />
          ) : (
            <Airtel
              activeTab="2"
              airtelData={data.airtel}
              onRefresh={refreshData}
            />
          )}
        </div>
        <div className="col-span-1">
          <h2>CRM</h2>
          {loading ? (
            <Spin size="large" />
          ) : (
            <CRM activeTab="1" crmData={data.crm} onRefresh={refreshData} />
          )}
        </div>

        <div className="col-span-1">
          <h2>Tata</h2>
          {loading ? (
            <Spin size="large" />
          ) : (
            <Tata activeTab="3" tataData={data.tata} onRefresh={refreshData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Team;
