import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import { DownloadOutlined } from "@ant-design/icons";
import { addReport } from "../../api/report";
import toast, { Toaster } from "react-hot-toast";
import { Spin } from "antd";

function Dialer_report({ selectedOption }) {
  const [csvLink, setCsvLink] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [arr, setArr] = useState([]);
  const [saving, setSaving] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);

  function getKeyForElement(element) {
    const lowerElement = element.toLowerCase();
    for (const key in mappedKeys) {
      const lowerKeys = mappedKeys[key].map((item) => item.toLowerCase());
      if (lowerKeys.includes(lowerElement)) {
        return key;
      }
    }
    return null; // If no key found for the element
  }
  const mappedKeys = {
    // customerNumber: ["Customer Number", "Customer Number"],
    ["Disposition Name"]: [
      "Agent Disposition",
      "Disposition Name",
      "status_name",
    ],
    ["Total Call Duration (HH:MM:SS)"]: [
      "Customer Talk Duration",
      "Total Call Duration (HH:MM:SS)",
      "length_in_sec",
    ],
    ["Campaign Name"]: ["Campaign Name", "Campaign Name", "campaign_name"],
    ["Connected to Agent"]: ["Connected to Agent", "Agent Name", "full_name"],
  };
  // Array of specific dispositions
  const specificDispositions = [
    "call rejected",
    "channel issue",
    "issue with receiver network",
    "noans",
    "network issue",
    "invalid dialing",
    "lead being called",
    "not reachable",
    "number changed",
    "system failure",
    "unallocated number",
    "Ring Timeout",
  ];

  const header = [
    "Busy",
    "Call Back",
    // "Interested",
    "INTRO",
    "Prospect",
    "Language Issue",
    "Need Update",
    "not interested",
    "not pickup",
    "300 sec",
    "DND",
    "scrap",
    "Undisposed",
    "NBFC LOAN",
  ];

  // Function to check if a disposition is present in the array
  const isSpecificDisposition = (disposition) => {
    return specificDispositions.includes(disposition);
  };

  const handleSave = async () => {
    try {
      const jsonData = JSON.stringify(arr);

      const data = await addReport(jsonData, selectedOption);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error("Error while saving:", error);
      toast.error("Error occurred while saving the report");
    }
  };

  // Function to convert duration to seconds
  function durationToSeconds(duration) {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  async function readAndParseFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const csvText = event.target.result;
          const parsedData = await parseCSVData(csvText);
          resolve(parsedData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsText(file);
    });
  }

  function parseCSVData(csvText) {
    return new Promise((resolve, reject) => {
      Papa.parse(csvText, {
        header: true,
        complete: (results) => {
          let framedData = [];
          let keys = [];
          Object.keys(results.data[0]).map((key) => {
            keys.push({ mappedKey: getKeyForElement(key), originalKey: key });
          });
          results.data.map((data) => {
            let obj = {};
            keys.forEach(({ originalKey, mappedKey }) => {
              obj[mappedKey] = data[originalKey];
            });
            framedData.push(obj);
          });

          resolve(framedData);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  const convertToCSV = (arr) => {
    const header = Object.keys(arr[0]).join(",");
    const rows = arr.map((obj) => Object.values(obj).join(","));
    return header + "\n" + rows.join("\n");
  };
  const downloadCSV = (arr) => {
    const csvContent = convertToCSV(arr);
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    setCsvLink(url);
  };
  const captureAndDownload = () => {
    const element = document.getElementById("Dialer_Report"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `Dialer-Report_${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const renderTable = () => {
    return (
      <>
        <table className="m-auto mt-3" id="Dialer_Report">
          <thead className="border border-black">
            <tr className="text-center">
              <th
                className="text-center pb-3 border-black border bg-[#92D14b] font-bold"
                colSpan={7}
              >
                Agents - AutoDialer Disposition Report{" "}
                {moment().format("DD-MM-YYYY")}
              </th>
            </tr>
            <tr className="border">
              <th
                className="border pb-3 text-center border-black p-2 bg-[#92D14b]"
                key={"sr no"}
              >
                Sr no.
              </th>
              {Object.keys(arr[0]).map((key) => (
                <th
                  className="border text-center p-2 border-black  bg-[#92D14b]"
                  key={key}
                >
                  {key === "header" ? "Disposition" : key.toUpperCase()}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {arr.map((row, index) => (
              <tr key={index} className="border pb-3 ">
                {row["header"] === "GRAND TOTAL" ||
                row["header"] === "TOTAL AGENTS" ||
                row["header"] === "TOTAL INTERESTED" ? (
                  <></>
                ) : (
                  <td
                    className={`border text-center ${
                      row["header"] === "300 SEC" ||
                      row["header"] === "INTERESTED"
                        ? "bg-[#92D14b]"
                        : row["header"] === "NOT INTERESTED"
                        ? "text-red-500"
                        : ""
                    }  p-1 font-bold border-black`}
                    // key={idx}
                  >
                    {index + 1}
                  </td>
                )}
                {Object.values(row).map((value, idx) => {
                  if (
                    value === "GRAND TOTAL" ||
                    value === "TOTAL AGENTS" ||
                    value === "TOTAL INTERESTED"
                  ) {
                    return (
                      <td
                        colSpan={2}
                        className={`border pb-3 ${
                          value === "GRAND TOTAL"
                            ? "bg-[#92D14b]"
                            : value === "TOTAL AGENTS"
                            ? "bg-[#ffd866]"
                            : value === "TOTAL INTERESTED"
                            ? "bg-[#f5b083]"
                            : ""
                        } text-center p-1 font-bold border-black`}
                        key={idx}
                      >
                        {value}
                      </td>
                    );
                  } else {
                    return (
                      <td
                        className={`border pb-3 ${
                          row["header"] === "GRAND TOTAL" ||
                          row["header"] === "300 SEC" ||
                          row["header"] === "INTERESTED"
                            ? "bg-[#92D14b]"
                            : row["header"] === "TOTAL AGENTS"
                            ? "bg-[#ffd866]"
                            : row["header"] === "TOTAL INTERESTED"
                            ? "bg-[#f5b083]"
                            : row["header"] === "NOT INTERESTED"
                            ? "text-red-500"
                            : ""
                        } text-center p-1 font-bold border-black`}
                        key={idx}
                      >
                        <p className="text">{value}</p>
                      </td>
                    );
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const parseFileData = async (files) => {
    try {
      const dataArray = await Promise.all(
        files.map(async (file) => {
          return await readAndParseFile(file);
        })
      );
      const mergedArray = [].concat(...dataArray); // or const mergedArray = dataArray.flat();
      return mergedArray;
    } catch (error) {
      throw error;
    }
  };

  // Function to handle file input change
  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (!files) {
      return;
    }
    const newSelectedFiles = [...selectedFiles, files[0]];
    const overallData = await parseFileData(newSelectedFiles);
    setDataSource(overallData);
    setSelectedFiles(newSelectedFiles);
  };

  const processData = () => {
    // Iterate over each row
    const report = {};
    dataSource.forEach((row) => {
      if (!row["Disposition Name"] || !row["Campaign Name"]) {
      } else {
        let disposition = row["Disposition Name"].toLocaleLowerCase();
        const team = row["Campaign Name"].toLocaleLowerCase();

        if (disposition === "do not call") disposition = "dnd";
        if (disposition === "not interesred") disposition = "not interested";

        const duration = row["Total Call Duration (HH:MM:SS)"]
          ? durationToSeconds(row["Total Call Duration (HH:MM:SS)"])
          : 0;
        // is disposition is not interested !
        // if ((disposition !== "interested" ) && duration >= 300) {
        if (
          // disposition !== "intro" &&
          // disposition !== "prospect" &&
          duration >= 300
        ) {
          if (!report["300 sec"]) {
            report["300 sec"] = {
              ["team a"]: 0,
              ["team b"]: 0,
              // ["team france"]: 0,
              ["chennai team"]: 0,
            };
          }
          if (duration >= 300) {
            report["300 sec"][team] += 1;
          }
        }

        // Ensure both disposition and team are defined
        if (disposition && team) {
          // Initialize count for the disposition if not present
          if (!report[disposition]) {
            report[disposition] = {};
          }

          // Increment count for the team and disposition
          if (!report[disposition][team]) {
            report[disposition][team] = 1;
          } else {
            report[disposition][team]++;
          }
        }
      }
    });

    Object.keys(report).forEach((key) => {
      if (isSpecificDisposition(key)) {
        report["not pickup"]["team a"] += parseInt(report[key]["team a"] || 0);
        report["not pickup"]["team b"] += parseInt(report[key]["team b"] || 0);
        // report["not pickup"]["team france"] += parseInt(
        //   report[key]["team france"] || 0
        // );
      }
      if (key === "wrong number") {
        report["scrap"]["team a"] += parseInt(report[key]["team a"] || 0);
        report["scrap"]["team b"] += parseInt(report[key]["team b"] || 0);
        // report["scrap"]["team france"] += parseInt(
        //   report[key]["team france"] || 0
        // );
        report["scrap"]["chennai team"] += parseInt(
          report[key]["chennai team"] || 0
        );
      }
      if (key === "receiver is busy") {
        report["busy"]["team a"] += parseInt(report[key]["team a"] || 0);
        report["busy"]["team b"] += parseInt(report[key]["team b"] || 0);
        // report["scrap"]["team france"] += parseInt(
        //   report[key]["team france"] || 0
        // );
        report["busy"]["chennai team"] += parseInt(
          report[key]["chennai team"] || 0
        );
      }
      if (key === "do not call") {
        report["dnd"]["team a"] += parseInt(report[key]["team a"] || 0);
        report["dnd"]["team b"] += parseInt(report[key]["team b"] || 0);
        // report["dnd"]["team france"] += parseInt(
        //   report[key]["team france"] || 0
        // );
        report["dnd"]["chennai team"] += parseInt(
          report[key]["chennai team"] || 0
        );
      }
    });
    let arr = [];

    header.forEach((key) => {
      key = key.toLocaleLowerCase();
      const teamAValue =
        report[key] && report[key]["team a"] ? report[key]["team a"] : 0;
      const teamBValue =
        report[key] && report[key]["team b"] ? report[key]["team b"] : 0;
      // const teamFranceValue =
      //   report[key] && report[key]["team france"]
      //     ? report[key]["team france"]
      //     : 0;
      const chennaiTeamValue =
        report[key] && report[key]["chennai team"]
          ? report[key]["chennai team"]
          : 0;

      arr.push({
        header: key,
        "team a": teamAValue,
        "team b": teamBValue,
        // "team france": teamFranceValue,
        "chennai team": chennaiTeamValue,
        "grand total":
          // teamAValue + teamBValue + teamFranceValue + chennaiTeamValue,
          teamAValue + teamBValue + chennaiTeamValue,
      });

      // }
    });

    const grandTotal = {
      header: "grand total",
      "team a": arr.reduce((total, item) => {
        if (item.header !== "300 sec") {
          return total + (item["team a"] || 0);
        }
        return total;
      }, 0),
      "team b": arr.reduce((total, item) => {
        if (item.header !== "300 sec") {
          return total + (item["team b"] || 0);
        }
        return total;
      }, 0),
      // "team france": arr.reduce((total, item) => {
      //   if (item.header !== "300 sec") {
      //     return total + (item["team france"] || 0);
      //   }
      //   return total;
      // }, 0),
      "chennai team": arr.reduce((total, item) => {
        if (item.header !== "300 sec") {
          return total + (item["chennai team"] || 0);
        }
        return total;
      }, 0),
      "grand total": arr.reduce((total, item) => {
        if (item.header !== "300 sec") {
          return total + (item["grand total"] || 0);
        }
        return total;
      }, 0),
    };
    arr.push(grandTotal);

    const totalInterested = {
      header: "Total Interested",
      "team a": arr.reduce((total, item) => {
        // if (item.header === "interested" || item.header === "300 sec") {
        if (
          item.header === "intro" ||
          item.header === "prospect" ||
          item.header === "300 sec"
        ) {
          return total + (item["team a"] || 0);
        }
        return total;
      }, 0),
      "team b": arr.reduce((total, item) => {
        if (
          item.header === "intro" ||
          item.header === "prospect" ||
          item.header === "300 sec"
        ) {
          return total + (item["team b"] || 0);
        }
        return total;
      }, 0),
      // "team france": arr.reduce((total, item) => {
      //   if (
      //     item.header === "intro" ||
      //     item.header === "prospect" ||
      //     item.header === "300 sec"
      //   ) {
      //     return total + (item["team france"] || 0);
      //   }
      //   return total;
      // }, 0),
      "chennai team": arr.reduce((total, item) => {
        if (
          item.header === "intro" ||
          item.header === "prospect" ||
          item.header === "300 sec"
        ) {
          return total + (item["chennai team"] || 0);
        }
        return total;
      }, 0),
      "grand total": arr.reduce((total, item) => {
        if (
          item.header === "intro" ||
          item.header === "prospect" ||
          item.header === "300 sec"
        ) {
          return total + (item["grand total"] || 0);
        }
        return total;
      }, 0),
    };
    arr.push(totalInterested);

    // Initialize an object to store the count of agents for each team
    const agentCounts = {
      "team a": new Set(),
      "team b": new Set(),
      // "team france": new Set(),
      "chennai team": new Set(),
    };

    // Iterate over each row of the CSV data
    dataSource.forEach((row) => {
      const team = `${row["Campaign Name"]}`.toLocaleLowerCase(); // Convert to lowercase
      const agent = row["Connected to Agent"];

      // Ensure both team and agent are defined
      if (team && agent) {
        // Add the agent to the set corresponding to their team
        agentCounts[team]?.add(agent);
      }
    });

    // Calculate the total count of agents for each team
    const totalAgents = {
      "team a": agentCounts["team a"].size,
      "team b": agentCounts["team b"].size,
      // "team france": agentCounts["team france"].size,
      "chennai team": agentCounts["chennai team"].size,
    };

    // Push the total agent count for each team into the arr array
    arr.push({
      header: "Total Agents",
      "team a": totalAgents["team a"],
      "team b": totalAgents["team b"],
      // "team france": totalAgents["team france"],
      "chennai team": totalAgents["chennai team"],
      "grand total": Object.values(totalAgents).reduce(
        (total, count) => total + count,
        0
      ),
    });

    arr.map((row) => {
      row.header = row.header.toUpperCase();
    });

    setArr(arr);
    downloadCSV(arr);
    handleSave();
  };

  // useEffect(() => {
  //   processData();
  // }, [selectedFiles]);

  const handleGenerateReport = () => {
    // Function to generate the report when the button is clicked
    processData();
  };

  // useEffect(() => {
  // }, [dataSource]);

  return (
    <div className="m-5">
      <h1 className="font-semibold">Excel to CSV Converter : </h1>
      <form className="flex flex-row w-fit gap-x-3">
        <input type="file" multiple onChange={handleFileInputChange} />
        <button
          type="button"
          onClick={handleGenerateReport}
          className="border-black cursor-pointer rounded-sm font-semibold border-2 p-1 text-center"
        >
          Generate Report
        </button>
        {csvLink && (
          <>
            <a
              href={csvLink}
              download="report.csv"
              className=" border-black cursor-pointer rounded-sm  font-semibold border-2  p-1 text-center"
            >
              Download CSV
            </a>
            <div
              onClick={captureAndDownload}
              className="border-black cursor-pointer rounded-sm  font-semibold border-2  p-1 text-center"
            >
              <DownloadOutlined style={{ fontSize: "20px", color: "#08c" }} />
              Download PNG
            </div>
          </>
        )}
      </form>
      <div>
        {selectedFiles.length > 0 && (
          <div className="">
            <h2 className="text-lg font-semibold mb-2">Selected Files:</h2>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index} className="flex items-center mb-1">
                  <span className="mr-2">{file.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {arr.length > 0 && renderTable()}
    </div>
  );
}

export default Dialer_report;
